import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Sheet} from 'react-modal-sheet';
import 'react-toastify/dist/ReactToastify.css';
import {
    addPost,
    getCreatorByUsername,
    getPostsByUsername, setProfilePicture,
    updateCreator,
    updatePost,
    uploadImage
} from '../api'; // No separate uploadImage import
import {useAuth} from "../auth/AuthProvider";
import {ToastContainer, toast} from 'react-toastify';
import {useJsApiLoader, Autocomplete, Libraries} from '@react-google-maps/api'; // Import Libraries
import './Profile.css';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"; // Masonry import
import {motion, AnimatePresence} from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';  // Add spinner import
import soltLogo from '../assets/logo.png';
import {HelmetProvider} from 'react-helmet-async';
import ReactGA from "react-ga4";
import posthog from 'posthog-js';


// Import the date-fns function for formatting relative dates
import {formatDistanceToNow} from 'date-fns';

const libraries: Libraries = ['places']; // Correct type for Google Maps libraries
const GOOGLE_MAPS_API_KEY = 'AIzaSyAx2tQrhPw3tAhUTdO1f4skW9zPpSZmyn4';
ReactGA.initialize("G-TLM63YPZGE");

interface Post {
    _id: string | number;
    images: string[]; // We will use only one image, but keeping it as an array for consistency
    title: string;
    description: string;
    location?: string;
    updated?: string;
    created?: string;
}

interface ProfileInfo {
    name?: string; // Optional with string | undefined
    username?: string;
    bio?: string;
    profileImage?: string;
    postsTitle?: string;
}

posthog.init('phc_Pz5SLU7O6UiCM3I5oabytoGoVTAkiMdROvO34hzDmiI',
    {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
    }
)

const Profile: React.FC = () => {
    const {username} = useParams<{ username: string }>();
    const {currentUser} = useAuth();
    const isAuthenticated = !!currentUser;
    // New state to track if the user is viewing their own profile
    const [isOwnProfile, setIsOwnProfile] = useState<boolean>(false);

    // console.log(currentUser);

    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedPost, setSelectedPost] = useState<Post | null>(null);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isViewing, setIsViewing] = useState<boolean>(false);
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>({
        name: '',
        bio: '',
        profileImage: '',
        postsTitle: '',
    });

    // State for editing profile info in the modal
    const [profileEditInfo, setProfileEditInfo] = useState<ProfileInfo>({
        name: '',
        bio: '',
        profileImage: '',
        postsTitle: '',
    });

    const [newPost, setNewPost] = useState<Post>({
        _id: 0,
        images: [],
        title: '',
        description: '',
        location: '',
    });

    const [isSheetOpen, setSheetOpen] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false); // New state for upload status

    const [fileToUpload, setFileToUpload] = useState<File | null>(null); // State to hold the file temporarily
    const [previewUrl, setPreviewUrl] = useState<string | null>(null); // State to store the preview URL

    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null); // State to manage autocomplete
    const [isProfileEditOpen, setProfileEditOpen] = useState<boolean>(false); // State for profile edit modal
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Loading state for animation

    const pageTitle = `${profileInfo.name} | Solt`;
    const pageDescription = `Follow my Solt page to see what and where I eat.`;

    const [canDrag, setCanDrag] = useState(false);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        console.log(scrollTop);
        setCanDrag(scrollTop === 0);  // Enable drag only when scrolled to the top
    };

    useEffect(() => {
        if (isViewing) {
            // Simulate loading time (for example, animation duration)
            const timeout = setTimeout(() => {
                setIsLoading(false);
            }, 500); // 0.3s to match the animation duration

            return () => clearTimeout(timeout); // Cleanup timeout if component unmounts
        } else {
            setIsLoading(true); // Reset loading when the modal is closed
        }
    }, [isViewing]);

    // useEffect(() => {
    //     if (username && pageTitle) {
    //         console.log('page_view');
    //         ReactGA.set({page: `/${username}`});
    //         ReactGA.send({hitType: "pageview", page: `/${username}`, pageTitle: `${pageTitle}`});
    //     }
    // }, [username, pageTitle]);

    useEffect(() => {
        if (isOverlayOpen) {
            // Add class to prevent background scrolling and interaction
            document.documentElement.classList.add('overlay-open');
        } else {
            // Remove class when the overlay is closed
            document.documentElement.classList.remove('overlay-open');
        }

        return () => {
            document.documentElement.classList.remove('overlay-open');
        };
    }, [isOverlayOpen]);


    // Fetch posts when component mounts or username changes
    useEffect(() => {
        // Fetch creator details by username
        const fetchCreator = async () => {
            setLoading(true);
            setError(null);

            try {
                if (username) {
                    const result = await getCreatorByUsername(username);

                    console.log('creator: ', result);

                    if (result === 'not-found') {
                        console.error('Creator not found');
                        setError('Creator not found.');
                    } else if (result === 'error') {
                        console.error('Failed to fetch creator data');
                        setError('Failed to load creator details. Please try again.');
                    } else {
                        console.log('Creator data:', result);
                        setProfileInfo({
                            name: result.name,
                            bio: result.bio || '',
                            profileImage: result.profileImage || './creator_profile_image_placeholder.png',
                            postsTitle: result.postsTitle || ''
                        });

                        // Also set the data in the profileEditInfo for editing in the modal
                        setProfileEditInfo({
                            name: result.name,
                            bio: result.bio || '',
                            profileImage: result.profileImage || './creator_profile_image_placeholder.png',
                            postsTitle: result.postsTitle || ''
                        });

                        if (result.firebaseUID === currentUser?.uid) {
                            setIsOwnProfile(true);
                        } else {
                            setIsOwnProfile(false);
                        }
                    }
                }
            } catch (error) {
                console.error('An error occurred while fetching creator details:', error);
                setError('An error occurred. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        const fetchPosts = async () => {
            setLoading(true);
            setError(null);

            const result = await getPostsByUsername(username || '');

            if (result === 'error') {
                setError('Failed to load posts. Please try again.');
            } else if (result === 'not-found') {
                setError('No posts found for this user.');
            } else if (result === 'missing-username') {
                setError('Username is required to fetch posts.');
            } else {
                setPosts(result);
            }

            setLoading(false);
        };

        if (username) {
            fetchCreator();
            fetchPosts(); // Trigger the API call
        }
    }, [username]);

    const handlePostSubmit = async () => {
        setUploading(true);
        let imageUrl: string | null = null;

        if (fileToUpload && currentUser) {
            try {
                const creatorId = currentUser.uid; // Assuming `creatorId` is available on the current user
                const uploadResult = await uploadImage(fileToUpload, creatorId); // Upload the image

                if (uploadResult === 'invalid-file') {
                    setUploading(false);
                    toast.error('Invalid file format. Please upload a valid image.');
                    return; // Stop submission if image upload fails
                } else if (uploadResult === 'upload-error') {
                    setUploading(false);
                    toast.error('Image upload failed. Please try again.');
                    return; // Stop submission if image upload fails
                } else {
                    imageUrl = uploadResult; // Successful upload returns URL
                }
            } catch (error) {
                console.error('Image upload failed:', error);
                toast.error('Failed to upload the image.');
                setUploading(false);
                return; // Stop submission if image upload fails
            }
        } else if (newPost.images.length > 0) {
            imageUrl = newPost.images[0]; // Use existing URL if no new file is uploaded
        }

        if (!imageUrl) {
            setUploading(false);
            toast.error('Image is required');
            return; // Stop if no image URL is available
        }

        const postToSubmit = {
            ...newPost,
            images: [imageUrl], // Use the uploaded image URL
            location: newPost.location || '',
        };

        if (isEditMode && selectedPost && selectedPost._id) {
            const result = await updatePost(username || '', selectedPost._id.toString(), postToSubmit);
            if (result === true) {
                const updatedPosts = posts.map(post =>
                    post._id === selectedPost._id ? {...post, ...postToSubmit} : post
                );
                setPosts(updatedPosts);
                toast.success('Post updated successfully!');
            } else {
                toast.error('Failed to update the post.');
            }
        } else {
            const result = await addPost(username || '', postToSubmit);
            if (result === true) {
                setPosts([...posts, {...postToSubmit, _id: new Date().getTime().toString()}]);
                toast.success('Post added successfully!');
            } else {
                toast.error('Failed to add the post.');
            }
        }

        setUploading(false);
        setIsEditMode(false);
        setSheetOpen(false);
        setIsOverlayOpen(false); // Close the overlay

        setSelectedPost(null);
        setFileToUpload(null); // Clear the temporary file state
        setPreviewUrl(null); // Clear the preview URL
    };

    const handleEditPost = (post: Post) => {
        if (isViewing) return;
        setIsViewing(false);
        // setSheetOpen(false);
        // setIsOverlayOpen(false);

        setNewPost(post);
        setSelectedPost(post); // Set the post to edit
        setIsEditMode(true);
        setSheetOpen(true);
        setIsOverlayOpen(true);
    };

    const handleAddPost = () => {
        setIsViewing(false);
        // setSheetOpen(false);
        // setIsOverlayOpen(false)

        setIsEditMode(true);
        setSelectedPost(null); // Clear the post
        setNewPost({
            _id: 0,
            images: [],
            title: '',
            description: '',
            location: '',
        });
        setSheetOpen(true); // Open modal for adding a new post
        setIsOverlayOpen(true);
    };

    const handleViewPost = (post: Post) => {
        if (isEditMode) return;


        console.log('dish_click');
        ReactGA.set({page: `/${username}`});
        ReactGA.event({
            category: 'dish_interaction',
            action: 'dish_click',
            label: post.title, // Post title
            value: Number(post._id), // Post ID as a value
            nonInteraction: false, // This is an interaction event
            transport: 'xhr' // Default transport method
        });

        // posthog.capture('dish_click', { name: post.title, page: `/${username}`, postId: post._id});
        posthog.capture('dish_click', {
            category: 'dish_interaction',
            action: 'click',
            title: post.title, // The title of the dish/post
            postId: post._id,  // Post ID
            path: window.location.pathname, // Captures the current path (/username)
            username: username
        });

        setIsEditMode(false);
        // setSheetOpen(false);
        // setIsOverlayOpen(false)

        setSelectedPost(post);
        setIsViewing(true);
        setSheetOpen(true);
        setIsOverlayOpen(true)
    };

    const closeModal = () => {
        setFileToUpload(null);
        setPreviewUrl(null);
        setSheetOpen(false);
        setIsEditMode(false);
        setIsViewing(false);
        setSelectedPost(null);
        setIsOverlayOpen(false);
        setProfileEditOpen(false);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0]; // Use the first file for now
            setFileToUpload(file); // Store the file in the temporary state
            const preview = URL.createObjectURL(file); // Generate the preview URL
            setPreviewUrl(preview); // Store the preview URL
            setNewPost(prevPost => ({
                ...prevPost,
                images: [preview], // Temporary URL for preview
            }));
        }
    };

    //google places things below
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || GOOGLE_MAPS_API_KEY, // Your Google Maps API Key
        libraries,
    });

    const onLoad = (autoC: google.maps.places.Autocomplete) => {
        console.log('onLoad');
        setAutocomplete(autoC);
    };

    const onPlaceChanged = () => {
        console.log('Place changed:', autocomplete);
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place && place.address_components) {
                const locationDetails = {
                    name: place.name || '',
                    street: place.address_components.find(component => component.types.includes('route'))?.long_name || '',
                    city: place.address_components.find(component => component.types.includes('locality'))?.long_name || '',
                    country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
                };
                console.log('Selected place details:', locationDetails);
                setNewPost({
                    ...newPost,
                    location: `${locationDetails.name}, ${locationDetails.street}, ${locationDetails.city}, ${locationDetails.country}`,
                });
            }
        } else {
            console.log('Autocomplete is not loaded.');
            toast.error('Autocomplete is not loaded.');
        }
    };

    const onUnmount = () => {
        console.log('Autocomplete component unmounted');
        setAutocomplete(null);

        const pacContainers = document.querySelectorAll('.pac-container');
        pacContainers.forEach(container => container!.parentNode!.removeChild(container));
    };


    //profile edit stuff

    // Profile Edit Submission (Handles Profile Image + Other Details)
    const handleProfileEditSubmit = async () => {
        if (!profileEditInfo) return;
        setUploading(true);

        let imageUrl = profileEditInfo.profileImage; // Keep existing image if not updated

        if (currentUser) {
            const email = currentUser.email;

            if (email) {
                // Handle profile picture upload if file selected
                console.log(fileToUpload);
                if (fileToUpload) {
                    try {
                        const creatorId = currentUser.uid; // Assuming creator ID is available
                        const uploadResult = await setProfilePicture(fileToUpload, creatorId);

                        if (uploadResult === 'invalid-file' || uploadResult === 'upload-error') {
                            toast.error('Profile picture upload failed');
                            return; // Stop submission if image upload fails
                        }

                        imageUrl = uploadResult; // Set new image URL
                        console.log(imageUrl);
                    } catch (error) {
                        toast.error('Profile picture upload failed');
                        setUploading(false);
                        return;
                    } finally {

                    }
                }

                // Proceed with updating the profile
                try {
                    const updateData = {
                        name: profileEditInfo.name,
                        bio: profileEditInfo.bio,
                        postsTitle: profileEditInfo.postsTitle,
                        profileImage: imageUrl // Use new or existing image URL
                    };

                    const result = await updateCreator(email!, updateData);
                    if (result === true) {
                        toast.success('Profile updated successfully');
                        setProfileInfo(updateData); // Update the displayed profile info after successful submission
                        setProfileEditOpen(false); // Close the modal
                    } else {
                        toast.error('Failed to update profile');
                    }
                } catch (error) {
                    toast.error('Failed to update profile');
                } finally {
                    setUploading(false);
                }

            }
        }

        setUploading(false);
    };

    // Handle profile image change for preview
    const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0]; // Use the first file for now
            setFileToUpload(file); // Store the file in the temporary state
            const preview = URL.createObjectURL(file); // Generate the preview URL
            setPreviewUrl(preview); // Store the preview URL
        }
    };

    const handleDragEnd = (event: any, info: any) => {
        // if (info.point.y > 5) {
        //     closeModal();
        // }
        closeModal();
    };

    const getRelativeTime = (dateString: string | undefined) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        return formatDistanceToNow(date, {addSuffix: true});  // Example: "3 days ago"
    };

    return (
        <>

            {/* Dynamic SEO metadata */}
            <HelmetProvider>
                {/*<head>*/}
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription}/>
            </HelmetProvider>
            {/*</head>*/}

            <div className="profile-container">
                {/* Profile Header */}
                {profileInfo && (
                    <div className="profile-header">
                        <div className="header-top">
                            <img src={soltLogo} alt="Solt logo" className="solt-logo"/>
                        </div>
                        <div className="profile-header-content">
                            <div className="profile-left">
                                <img
                                    src={profileInfo.profileImage} // Use preview if available
                                    alt={profileInfo.name}
                                    className="profile-image"
                                />
                                <div className="profile-info">
                                    <h1>{profileInfo.name}</h1>
                                    <p className="profile-username">@{username}</p>
                                </div>
                            </div>
                            <div className="profile-stats">
                                <div className="stat-item">
                                    <span className="stat-count">{posts?.length}</span>
                                    <span className="stat-label">
                                      {posts?.length === 1 ? 'dish' : 'dishes'}
                                    </span>
                                </div>
                                <div className="stat-item">
                                    <span className="stat-count">
                                      {posts?.filter(post => post.location).length}
                                    </span>
                                    <span className="stat-label">
                                      {posts?.filter(post => post.location).length === 1 ? 'location' : 'locations'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
                {profileInfo && <p className="profile-bio">{profileInfo.bio}</p>}

                <div className={"bar"}/>

                {/* Post title section */}
                {profileInfo && <h2 className="post-title">{profileInfo.postsTitle}</h2>}


                {/* Loading and error states */}
                {loading && <div className="loading-message"><p>Loading posts...</p></div>}
                {error && <div className="error-message"><p>{error}</p></div>}

                {/* Admin controls for adding a new dish */}
                {isAuthenticated && isOwnProfile && (
                    <div className="admin-controls">
                        <button onClick={() => setProfileEditOpen(true)}>Edit profile</button>
                        <button onClick={handleAddPost}>Add new dish</button>
                    </div>
                )}

                {/* Post Masonry Grid */}
                {!loading && posts.length > 0 ? (
                    <ResponsiveMasonry columnsCountBreakPoints={{1: 1, 350: 2, 900: 3}}>
                        <Masonry gutter="15px">
                            {posts.map((post) => (
                                <div key={post._id} className="post-card"
                                     onClick={() => !isEditMode && handleViewPost(post)}>
                                    <img src={post.images[0]} alt={post.title} className="post-image"/>
                                    <div className="post-info">
                                        <h3>{post.title}</h3>
                                        {post.location && <p>{post.location}</p>}
                                        {post.created && <span>{getRelativeTime(post.created)}</span>}
                                        {isAuthenticated && isOwnProfile && (
                                            <button className="edit-btn" onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditPost(post);
                                            }}>Edit</button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                ) : (
                    !loading && <div className="no-posts-message"><p>No posts available.</p></div>
                )}


                {/* Modal sheet for editing profile */}
                <AnimatePresence>
                    {isProfileEditOpen && (
                        <motion.div
                            className="overlay"
                            initial={{opacity: 0, y: "100%"}}
                            animate={{opacity: 1, y: "0%"}}
                            exit={{opacity: 0, y: "100%"}}
                            transition={{duration: 0.2, ease: "easeInOut"}}
                            // drag="y"
                            // dragConstraints={{ top: 0, bottom: 10 }}
                            // onDragEnd={handleDragEnd}
                        >
                            {/*<Sheet*/}
                            {/*    isOpen={isProfileEditOpen}*/}
                            {/*    onClose={() => setProfileEditOpen(false)}*/}
                            {/*    snapPoints={[0.7]}*/}
                            {/*    tweenConfig={{ease: 'easeInOut', duration: 0.3}}*/}
                            {/*    style={{zIndex: 100}}*/}
                            {/*>*/}
                            {/*    <Sheet.Container>*/}
                            {/*        <Sheet.Header/>*/}
                            {/*        <Sheet.Content>*/}
                            <div className="overlay-content">

                                <div className="bottom-sheet-content">
                                    {/* Profile picture upload */}
                                    <div className="image-previews">
                                        <img
                                            src={previewUrl || profileEditInfo?.profileImage} // Use preview if available
                                            alt="Profile Preview"
                                            className="image-preview"
                                        />
                                    </div>

                                    <label htmlFor="profile-image">Profile Picture</label>
                                    <input
                                        id="profile-image"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleProfileImageChange}
                                    />

                                    {/* Profile Name */}
                                    <label htmlFor="profile-name">Name</label>
                                    <input
                                        id="profile-name"
                                        type="text"
                                        value={profileEditInfo?.name || ''}
                                        onChange={(e) => setProfileEditInfo({...profileEditInfo, name: e.target.value})}
                                        placeholder="Enter your name"
                                    />

                                    {/* Profile Bio */}
                                    <label htmlFor="profile-bio">Bio</label>
                                    <textarea
                                        id="profile-bio"
                                        value={profileEditInfo?.bio || ''}
                                        onChange={(e) => setProfileEditInfo({...profileEditInfo, bio: e.target.value})}
                                        placeholder="Enter your bio"
                                    />

                                    {/* Posts Title */}
                                    <label htmlFor="posts-title">Posts Title</label>
                                    <input
                                        id="posts-title"
                                        type="text"
                                        value={profileEditInfo?.postsTitle || ''}
                                        onChange={(e) => setProfileEditInfo({
                                            ...profileEditInfo,
                                            postsTitle: e.target.value
                                        })}
                                        placeholder="Enter your posts title"
                                    />

                                    {/* Submit button */}
                                    <button onClick={handleProfileEditSubmit} className="form-submit-btn">
                                        {uploading ? <ClipLoader color={'#fff'} size={20}/> : 'Update'}
                                    </button>

                                    <button onClick={closeModal} className="form-cancel-btn">Cancel</button>

                                </div>
                            </div>

                        </motion.div>
                    )}
                </AnimatePresence>
                {/*        </Sheet.Content>*/}
                {/*    </Sheet.Container>*/}
                {/*    <Sheet.Backdrop/>*/}
                {/*</Sheet>*/}


                {/* overlay sheet for adding/editing a post */}
                <AnimatePresence>
                    {isEditMode && (
                        <motion.div
                            className="overlay"
                            initial={{opacity: 0, y: "100%"}}
                            animate={{opacity: 1, y: "0%"}}
                            exit={{opacity: 0, y: "100%"}}
                            transition={{duration: 0.2, ease: "easeInOut"}}
                            // drag="y"
                            // dragConstraints={{ top: 0, bottom: 10 }}
                            // onDragEnd={handleDragEnd}
                        >
                            <div className="overlay-content">
                                <div className="bottom-sheet-content">


                                    {/* Image preview */}
                                    {previewUrl ? (
                                        <div className="image-previews">
                                            <img src={previewUrl} alt="Preview" className="image-preview"/>
                                        </div>
                                    ) : (newPost.images[0] && (
                                            <div className="image-previews">
                                                <img src={newPost.images[0]} alt="Preview"
                                                     className="image-preview"/>
                                            </div>
                                        )
                                    )}

                                    {/* Image upload */}
                                    <label htmlFor="image">Image</label>
                                    <input
                                        id="image"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />

                                    {/* Post title */}
                                    <label htmlFor="title">Dish Title</label>
                                    <input
                                        id="title"
                                        type="text"
                                        value={newPost.title}
                                        onChange={e => setNewPost({...newPost, title: e.target.value})}
                                        placeholder="Enter dish title"
                                    />


                                    {/* Autocomplete for restaurant location */}
                                    <label htmlFor="location">Restaurant Location</label>
                                    {isSheetOpen && isEditMode && isLoaded && (
                                        <Autocomplete
                                            onLoad={onLoad}
                                            onPlaceChanged={onPlaceChanged}
                                            onUnmount={onUnmount}
                                        >
                                            <div className="autocomplete-container">
                                                <input
                                                    id="location"
                                                    type="text"
                                                    placeholder="Search for restaurant"
                                                    className="form-input"
                                                    value={newPost.location}
                                                    onChange={e => setNewPost({
                                                        ...newPost,
                                                        location: e.target.value
                                                    })}
                                                />
                                            </div>
                                        </Autocomplete>
                                    )}

                                    {/* Post description */}
                                    <label htmlFor="description">Description</label>
                                    <textarea
                                        id="description"
                                        value={newPost.description}
                                        onChange={e => setNewPost({...newPost, description: e.target.value})}
                                        placeholder="Enter description"
                                    />

                                    {/* Submit button */}
                                    <button onClick={handlePostSubmit} className="form-submit-btn">
                                        {uploading ? <ClipLoader color={'#fff'} size={20}/> : 'Submit'}
                                    </button>

                                    <button onClick={closeModal} className="form-cancel-btn">Cancel</button>

                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>


                {/* overlay sheet for viewing a post */}
                <AnimatePresence>
                    {isViewing && (
                        <motion.div
                            className="overlay"
                            initial={{opacity: 0, y: "100%"}}
                            animate={{
                                opacity: 1, y: "0%",
                                // transition: {
                                //     ease: "linear",
                                // }

                            }}
                            transition={{
                                y: {type: "tween"},
                                duration: 0.2,

                            }}
                            exit={{opacity: 0, y: "100%"}}
                            // transition={{duration: 0.1, ease: 'easeInOut'}}
                        >
                            {selectedPost && (
                                <>
                                    <div className="overlay-content">
                                        <div className="bottom-sheet-content">
                                            {/* Show loader while loading */}
                                            {isLoading ? (
                                                <div className="loading-container">
                                                    <ClipLoader color="#000" size={40}/>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="fixed-image-box">
                                                        <img
                                                            src={selectedPost.images[0]}
                                                            alt={selectedPost.title}
                                                            className="fixed-image"
                                                        />
                                                    </div>

                                                    {/* Dish Title */}
                                                    <div className="title-info">
                                                        <h3>{selectedPost.title}</h3>
                                                    </div>

                                                    {selectedPost.created && (
                                                        <span className="timestamp-info">
                                                    Added {getRelativeTime(selectedPost.created)}
                                                </span>
                                                    )}

                                                    <div className="bar"></div>

                                                    <div className="info-card">
                                                        <h4>Where to eat:</h4>
                                                        <p className="location-text">{selectedPost.location}</p>
                                                    </div>

                                                    <div className="bar"></div>

                                                    <div className="info-card">
                                                        <h4>Description:</h4>
                                                        <p className="description-text">
                                                            {selectedPost.description}
                                                        </p>
                                                    </div>

                                                    <div className="buttons-container">
                                                        <button className="form-submit-btn"
                                                                style={{textDecoration: 'none'}}
                                                                onClick={() => {
                                                                    console.log('google_maps_click');
                                                                    ReactGA.set({page: `/${username}`});
                                                                    ReactGA.event({
                                                                        category: 'external_link',
                                                                        action: 'google_maps_click',
                                                                        label: selectedPost?.location, // Optional: location name
                                                                        value: Number(selectedPost?._id), // Optional: Post ID or any relevant value
                                                                        nonInteraction: false, // Interaction event
                                                                        transport: 'xhr' // Default transport method
                                                                    });

                                                                    // PostHog tracking
                                                                    posthog.capture('google_maps_click', {
                                                                        category: 'external_link',
                                                                        action: 'click',
                                                                        location: selectedPost?.location, // Location name
                                                                        postId: selectedPost?._id,  // Post ID
                                                                        path: window.location.pathname, // Captures the current path (/username)
                                                                        username: username
                                                                    });
                                                                }}>
                                                            <a
                                                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                                                    selectedPost.location || ""
                                                                )}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{textDecoration: 'none', color: 'white'}}
                                                                className=""
                                                            >
                                                                Open in Google Maps
                                                            </a>
                                                        </button>
                                                        <button onClick={closeModal} className="form-cancel-btn">
                                                            Close
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Toast notification */}
                <ToastContainer
                    autoClose={1000}
                    theme="colored"
                    draggable
                    hideProgressBar
                    closeButton={false}
                    newestOnTop
                />
            </div>

            {/* Footer */}

            <div className="profile-footer">
                <div className={"bar"}/>
                {/*<a href="https://solt.app" target="_blank" rel="noopener noreferrer">*/}
                {/*    <img className="footer-logo" src={soltLogo} alt="Solt Logo"/>*/}
                {/*</a>*/}
                {/*<p>Powered by <a href="https://solt.app" target="_blank" rel="noopener noreferrer">Solt</a>.</p>*/}
                <button className="footer-button" onClick={() => window.open('https://solt.link', '_blank')}>Create your
                    own Solt link
                </button>

            </div>

        </>
    );
};

export default Profile;

