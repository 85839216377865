import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    apiKey: "AIzaSyBNWkCkZzOad3dgM1F6Uxo4BeGIeSVGXL8",
    // authDomain: 'localhost:5002',
    // authDomain: "verified-goblin-fluent.ngrok-free.app",
    authDomain: "solt.link",
    // authDomain: "solt-creators.firebaseapp.com",
    projectId: "solt-creators",
    storageBucket: "solt-creators.appspot.com",
    messagingSenderId: "616336347961",
    appId: "1:616336347961:web:cb5240a5daa5b41fa84ee8",
    measurementId: "G-TLM63YPZGE"
};

// const firebaseConfig = {
//     apiKey: "AIzaSyBNWkCkZzOad3dgM1F6Uxo4BeGIeSVGXL8",
//     authDomain: "solt-creators.firebaseapp.com",
//     projectId: "solt-creators",
//     storageBucket: "solt-creators.appspot.com",
//     messagingSenderId: "616336347961",
//     appId: "1:616336347961:web:cb5240a5daa5b41fa84ee8",
//     measurementId: "G-TLM63YPZGE"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export default app;
